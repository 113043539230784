























































































































import { debounceProcess } from "@/helpers/debounce";
import { useBlob, useDate } from "@/hooks";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import {
  DATE_TIME_DEFAULT_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { DataResponseJournalTransaction } from "@/models/interface/accounting.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { accountingService } from "@/services/accounting.service";
import { settingsServices } from "@/services/settings.service";
import { SorterProps } from "@/types";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class JournalTransactions extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form!: WrappedFormUtils;
  totalData = 0;
  page = 1;
  limit = 10;
  sortBy = "";
  dataSource: DataResponseJournalTransaction[] = [];
  loading = {
    accountNumber: false,
    find: false,
    download: false,
  };
  dataAccountNumber: any[] = [];
  downloadParams = {
    accountNumber: "",
    periodFrom: "",
    periodTo: "",
    companyName: "",
  };
  columnsTable = [
    {
      title: this.$t("lbl_journal_date"),
      dataIndex: "journalDate",
      key: "journalDate",
      width: 150,
      sorter: true,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNumber",
      key: "journalNumber",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_account_number"),
      dataIndex: "accountNumber",
      key: "accountNumber",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_account_name"),
      dataIndex: "accountName",
      key: "accountName",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_line_description"),
      dataIndex: "lineDescription",
      key: "lineDescription",
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_journal_reference"),
      dataIndex: "journalReference",
      key: "journalReference",
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_debet"),
      dataIndex: "debit",
      key: "debet",
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_credit"),
      dataIndex: "credit",
      key: "credit",
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_balance"),
      dataIndex: "balance",
      key: "balance",
      scopedSlots: { customRender: "isCurrency" },
    },
  ];
  formRules = {
    accountNumber: {
      label: "lbl_account_number",
      name: "lbl_account_number",
      placeholder: "lbl_account_number_placeholder",
      decorator: [
        "accountNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    periodFrom: {
      label: "lbl_period_from",
      name: "lbl_period_from",
      placeholder: "lbl_period_from_placeholder",
      decorator: [
        "periodFrom",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    periodTo: {
      label: "lbl_period_to",
      name: "lbl_period_to",
      placeholder: "lbl_period_to_placeholder",
      decorator: [
        "periodTo",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "journalTransactions" });
  }

  created(): void {
    this.searchData = debounceProcess(this.searchData, 500);
  }

  mounted() {
    this.getAccountNumber("");
  }

  searchData(value, _type) {
    this.getAccountNumber(value);
  }

  getAccountNumber(value) {
    let params = {
      page: 0,
      limit: 10,
    } as RequestQueryParamsModel;
    if (value) params.search = `code~*${value}*_OR_description~*${value}*`;
    settingsServices.listOfCoa(params, "").then(res => {
      res.data.forEach(element => {
        element["label"] = `${element.code} - ${element.description}`;
      });
      this.dataAccountNumber = res.data;
    });
  }

  onclickButton(type, triger) {
    this.form.validateFields((err, value) => {
      if (!err) {
        const params: RequestQueryParamsModel = {
          page: triger == "Yes" ? this.page - 1 : 0,
          limit: triger == "Yes" ? this.limit : 10,
          search: "",
          sorts: this.sortBy,
        };
        const { toStartDay, toEndDay } = useDate();
        if (value.accountNumber) {
          params.accountId = value.accountNumber;
        }

        if (value.periodFrom) {
          params.dateFrom = toStartDay(value.periodFrom).format(
            DATE_TIME_DEFAULT_FORMAT
          );
        }

        if (value.periodTo) {
          params.dateTo = toEndDay(value.periodTo).format(
            DATE_TIME_DEFAULT_FORMAT
          );
        }

        if (type != "reset") {
          if (type == "download") {
            params.params = `${
              this.$store.state.authStore.authData.companyName ||
              "PT. SATRIA PIRANTI PERKASA"
            },${
              value.accountNumber
                ? this.dataAccountNumber.find(
                    item => item.id === value.accountNumber
                  )?.label
                : "ALL"
            },${
              value.periodFrom
                ? moment(value.periodFrom).format(DEFAULT_DATE_FORMAT)
                : "ALL"
            },${
              value.periodTo
                ? moment(value.periodTo).format(DEFAULT_DATE_FORMAT)
                : "ALL"
            }`;
            this.loading.download = true;
            accountingService
              .getJournalTransactionDownload(params)
              .then(res => {
                if (res) {
                  const { toDownload } = useBlob();
                  toDownload(res, "general_ledger.xlsx");
                }
              })
              .finally(() => (this.loading.download = false));
          } else {
            this.loading.find = true;
            accountingService
              .getJournalTransaction(params)
              .then(res => {
                this.dataSource = res.data;
                this.totalData = res.totalElements;
              })
              .finally(() => {
                this.loading.find = false;
              });
          }
        } else {
          this.form.resetFields();
        }
      }
    });
  }

  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 1;
    this.onclickButton("find", "Yes");
  }
  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page;
    this.onclickButton("find", "Yes");
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  onTableChange({ sort }: { sort: SorterProps }): void {
    const { columnKey, order } = sort;
    this.sortBy = "";
    if (order === "ascend") {
      this.sortBy = `${columnKey}:asc`;
    } else if (order === "descend") {
      this.sortBy = `${columnKey}:desc`;
    }
    this.onclickButton("find", "Yes");
  }
}
