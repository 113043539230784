var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_journal_transactions_report") } },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form",
                _vm._b(
                  { attrs: { layout: "vertical", form: _vm.form } },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12, xs: 24, sm: 24, md: 12 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.accountNumber.label)
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value:
                                        _vm.formRules.accountNumber.decorator,
                                      expression:
                                        "formRules.accountNumber.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name: _vm.formRules.accountNumber.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.accountNumber.placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                    loading: _vm.loading.accountNumber,
                                    allowClear: true
                                  },
                                  on: {
                                    search: function(value) {
                                      return _vm.searchData(
                                        value,
                                        "accountNumber"
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.dataAccountNumber, function(
                                  data,
                                  index
                                ) {
                                  return _c(
                                    "a-select-option",
                                    { key: index, attrs: { value: data.id } },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " + _vm._s(data.label) + " "
                                            )
                                          ]),
                                          _vm._v(" " + _vm._s(data.label) + " ")
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.periodFrom.label)
                              }
                            },
                            [
                              _c("a-date-picker", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.periodFrom.decorator,
                                    expression: "formRules.periodFrom.decorator"
                                  }
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.periodFrom.placeholder
                                  ),
                                  format: _vm.DEFAULT_DATE_FORMAT
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.periodTo.label)
                              }
                            },
                            [
                              _c("a-date-picker", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.periodTo.decorator,
                                    expression: "formRules.periodTo.decorator"
                                  }
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.periodTo.placeholder
                                  ),
                                  format: _vm.DEFAULT_DATE_FORMAT
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.onclickButton("reset", "All")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading.find },
                      on: {
                        click: function($event) {
                          return _vm.onclickButton("find", "No")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_find")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "mt-4", attrs: { span: 24 } },
            [
              _c(
                "a-tabs",
                { attrs: { type: "card" } },
                [
                  _c(
                    "a-tab-pane",
                    {
                      key: "1",
                      staticClass: "bordertabs p-2",
                      attrs: { tab: _vm.$t("lbl_journal_transactions_report") }
                    },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { staticClass: "mt-4", attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  dataSource: _vm.dataSource,
                                  columns: _vm.columnsTable,
                                  scroll: { x: "calc(1300px + 50%)", y: 700 },
                                  paginationcustom: true,
                                  defaultPagination: false,
                                  loading: _vm.loading.find
                                },
                                on: { "on-tablechange": _vm.onTableChange }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-space",
                { staticStyle: { height: "50px" }, attrs: { align: "center" } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.totalData,
                      pageSizeSet: _vm.limit,
                      idPagination: "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "mt-3", attrs: { span: 12, align: "end" } },
            [
              _c(
                "a-tag",
                {
                  staticStyle: { "font-size": "13px" },
                  attrs: { color: "grey" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("lbl_total_found")) +
                      " : " +
                      _vm._s(_vm.totalData) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "mt-3", attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-button",
                {
                  staticClass: "mr-2",
                  attrs: { type: "primary", loading: _vm.loading.download },
                  on: {
                    click: function($event) {
                      return _vm.onclickButton("download", "All")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }