import { Api } from "@/models/class/api.class";
import { ResponseJournalTransaction } from "@/models/interface/accounting.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class AccountingService extends HttpClient {
  getJournalTransaction(
    params: RequestQueryParamsModel
  ): Promise<ResponseJournalTransaction> {
    return this.get<ResponseJournalTransaction>(Api.JournalTransaction, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getJournalTransactionDownload = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.JournalTransaction + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getJournalTransactionDetailDownload = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.AccountingReport + "/general-journal/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  downloadReportProfitLoss = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.AccountingReport + `/profit-loss`, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  downloadReportBalanceSheet = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.AccountingReport + `/balance-sheet`, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}

export const accountingService = new AccountingService();
